<script lang="ts" setup>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import type { Swiper as ISwiper, SwiperOptions } from 'swiper/types';
  import type { ICollections, ISeriesInfo } from '~/types/movies';
  import CenteredSliderItem from '~/components/sliders/centered/CenteredSliderItem.vue';
  import type { ExtractPublicPropTypes } from 'vue';

  interface IProps {
    items: ICollections[];
    initialSlideIndex?: number;
  }
  const props = defineProps<IProps>();
  const emit = defineEmits<{
    beforeDestroy: [typeof Swiper];
    reachEnd: [];
  }>();
  const options: SwiperOptions = {
    speed: 700,
    slidesPerView: 1,
    spaceBetween: 32,
    loop: false,
    breakpoints: {
      640: {
        slidesPerView: 1.4,
      },
    },
    init: false,
    initialSlide: props.initialSlideIndex,
  };
  const settings: ExtractPublicPropTypes<typeof Swiper> = { ...options };

  const loadNext = (slider: ISwiper) => {
    const prepareForLoading = slider.activeIndex >= props.items.length - 3;
    if (prepareForLoading) emit('reachEnd');
  };
</script>

<template>
  <div class="centered-slider">
    <nuxt-error-boundary>
      <swiper v-bind="settings" @before-destroy="emit('beforeDestroy', $event)" @slideChange="loadNext">
        <swiper-slide v-for="(slide, index) in props.items" :key="index">
          <centered-slider-item :collection="slide" />
        </swiper-slide>
      </swiper>
    </nuxt-error-boundary>
  </div>
</template>

<style lang="scss">
  .centered-slider {
    position: relative;
    .swiper {
      height: 100%;
      position: relative;
      display: grid;
      transition:
        opacity 0.6s ease,
        transform 0.3s ease;
      .swiper-slide {
        transition: opacity 0.3s ease;
      }
    }
    @media (max-width: $retina) {
      padding: 0 1rem;
    }
  }
</style>
